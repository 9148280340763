@import "variables";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;

  &:active,
  &:focus, {
    outline-color: $color-green-main;
  }
}

button {
  color: inherit;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: $font-size-base;
}

a {
  color: inherit;
  text-decoration: none;
}

.contentText {
  p {
    font-size: $font-size-base;
    line-height: $font-size-xxl;
    margin-bottom: $spacing-3;
  }

  a {
    color: $color-green-main;
    text-decoration: underline;
  }

  b {
    font-weight: 500;
  }

  ul, ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }
  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  li:not(:last-child) {
    margin-bottom: $spacing-2;
  }

  ul ul, ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }
}
